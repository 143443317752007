// import Swiper JS
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export function swiper(opts = {}) {
  let swiper = null;
  let swiperEl = null;

  return {
    activeIndex : 0,
    previousIndex : null,
    nextIndex : null,
    init() {
      let self = this;
      swiperEl = this.$refs.swiperEl || this.$root;
      swiper = new Swiper(swiperEl, {
        modules: [ Navigation, Pagination ],
        navigation: {
          nextEl: this.$refs['swiper-button-next'],
          prevEl: this.$refs['swiper-button-prev'],
        },
        on: {
          slideChangeTransitionStart: function() {
            self.previousIndex = self.activeIndex;
            self.nextIndex = swiper.activeIndex;
            self.activeIndex = null;
          },
          slideChangeTransitionEnd: function() {
            self.previousIndex = null;
            self.nextIndex = null;
            self.activeIndex = swiper.activeIndex;
          },
        },
        pagination: {
          el: this.$refs['swiper-pagination'],
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><circle cx="1" cy="1" r="1" /></svg></span>';
          },
        },
        speed: 600,
        ...opts
      });
    },
  };
}

export function lightboxSlideshow(opts = {}) {
  let swiper = null;
  let swiperEl = null;

  return {
    swiperIndex: 0,
    init() {
      swiperEl = this.$refs.lightboxSwiperEl || this.$root;
      swiper = new Swiper(swiperEl, {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        speed: 600,
        keyboard: {
          enabled: true,
        },
        navigation: {
          nextEl: this.$root.querySelector('.swiper-button-next'),
          prevEl: this.$root.querySelector('.swiper-button-prev'),
        },
        pagination: {
          el: this.$root.querySelector('.swiper-pagination'),
          type: 'fraction',
        },
        modules: [Navigation, Pagination],
        ...opts
      });

      swiper.on('slideChangeTransitionStart', () => {
        this.swiperIndex = swiper.realIndex;
      });

      window.addEventListener('lightbox-open', (event) => {
        if(event.detail.open) {
          swiper.slideToLoop(event.detail.slideIndex, 0, false);
          swiper.update();

          this.swiperIndex = event.detail.slideIndex;
        }
      });

      window.addEventListener('lightbox-prev', (event) => {
        swiper.slidePrev();
      });

      window.addEventListener('lightbox-next', (event) => {
        swiper.slideNext();
      });
    }
  };
}
