import Alpine from 'alpinejs';
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse';

import embedAssetResizer from './embedAssetResizer';
import { swiper, lightboxSlideshow } from './swiper';

Alpine.plugin(focus);
Alpine.plugin(collapse);

Alpine.data('embedAssetResizer', embedAssetResizer);
Alpine.data('swiper', swiper);
Alpine.data('lightboxSlideshow', lightboxSlideshow);

window.Alpine = Alpine;
Alpine.start();
