export default function(width, height, selector = 'iframe') {

  let iframeEl = null;

  return {
    width,
    height,

    get ratio() {
      return this.height / this.width;
    },

    get inverseRatio() {
      return this.width / this.height;
    },

    init() {
      iframeEl = this.$root.querySelector(selector);
      setTimeout(() => {
        this.resize();
      }, 500);
    },

    resize() {
      const w = this.$el.offsetWidth;
      const h = this.$el.offsetHeight;
      const containerRatio = h / w;

      if (containerRatio > this.ratio) {
        let width = h * this.inverseRatio;
        iframeEl.style = `transform: translate(${(w - width) / 2}px, 0);`;
        iframeEl.style.width = `${width}px`;
        iframeEl.style.height = `${h}px`;
      } else {
        let height = w * this.ratio;
        iframeEl.style = `transform: translate(0, ${(h - height) / 2}px);`;
        iframeEl.style.width = `${w}px`;
        iframeEl.style.height = `${height}px`;
      }
    }

  };
}
