/**
 * Array of CSS selectors to follow & preload (see below)
 * @type {string[]}
 */
const yesSelectors = ['a[href]'];

/**
 * Array of CSS selectors to NOT follow & preload (see below)
 * @type {string[]}
 */
const noSelectors = [
  'a[href$="/contact"]',
];

/**
 * Run JS that's returned in a fragment
 * @type {boolean}
 */
// up.fragment.config.runScripts = true;

/**
 * When an updated fragments contain an element
 * matching one of the given target selectors,
 * history will be updated with { history: 'auto' }.
 * @type {string[]}
 */
up.fragment.config.autoHistoryTargets = [
  '[up-main]',
];

/**
 * Disable network cache
 */
up.network.config.autoCache = false;

/**
 * Automatically follow links
 * https://unpoly.com/up.link.config#config.followSelectors
 * https://unpoly.com/up.link.config#config.noFollowSelectors
 */
up.link.config.followSelectors.push(...yesSelectors);
up.link.config.noFollowSelectors.push(...noSelectors);

/**
 * Automatically preload links
 * https://unpoly.com/up.link.config#config.preloadSelectors
 * https://unpoly.com/up.link.config#config.noPreloadSelectors
 */
up.link.config.preloadSelectors.push(...yesSelectors);
up.link.config.noPreloadSelectors.push(...noSelectors);
