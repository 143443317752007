/**
 * Track google pageviews
 */
up.on('up:location:changed', ({ location }) => {
  try {
    console.log('Track GA pageview: ' + location);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': location
    });
  } catch(e) {
    console.error('Track pageview error: ' + e.message);
  }
});
